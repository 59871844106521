import { fetchConfig } from '@/lib/fetchConfig';
import { useQuery } from '@tanstack/react-query';

export const queryHomeIncyber = `query ($locale: String!) {
  homePageCollection(
    limit: 1
    locale: $locale
    where: { internalName: "Home page Incyber" }
  ) {
    __typename
    items {
      pageName
      titleDescription {
        json
      }
      slug
      sectionsCollection(limit: 10) {
        __typename
        items {
          ... on ArticleSection {
            __typename
            sys {
              id
            }
            title
            layout
            hasHoverEffect
            numberOfLoadedContent
            sectionContentCollection(limit: 100) {
              items {
                ... on Article {
                  featuredTitle
                  featuredDescription {
                    json
                  }
                  slug
                  category
                  expertise
                  services
                  industry
                  featuredImage {
                    width
                    height
                    url
                    title
                  }
                  featuredVideo {
                    title
                    url
                  }
                }
              }
            }
          }
          ... on ContentFlag {
            __typename
            internalTitle
            isConsulteerPage
          }
          ... on CardSection {
            __typename
            internalTitle
            sectionPadding
            title
            layout
            numberOfLoadedCards
            sectionContentCollection {
              items {
                ... on Card {
                  __typename
                  sys {
                    id
                  }
                  internalTitle
                  title
                  linkUrl
                  onlyImagevideo
                  shortDescription {
                    json
                  }
                  icon {
                    url
                    width
                    height
                  }
                  linkIconTitle
                  cardTemplate
                  cardImage {
                    width
                    height
                    url
                    title
                  }
                  videoUrl
                }
                ... on Accordion {
                  __typename
                  title
                  description {
                    json
                  }
                }
              }
            }
          }
          ... on Quote {
            __typename
            quoteText
            author
            authorTitle
          }
          ...on AwardsAndCertificates {
           __typename
           title
           imagesCollection {
             items {
               title
               description
               url
               size
             }
           }
          }
          ... on PersonBanner {
            __typename
            sys {
              id
            }
            internalTitle
            quote
            picturePosition
            pictureOfPerson {
              title
              description
              url
              width
              height
            }
            linkedIn {
              title
              urlPath
              document {
                title
                description
                url
                width
                height
              }
            }
            personName
            personPosition
            personMail
          }
        }
      }
      containerForCustomButton {
        __typename
        sys {
          id
        }
        internalTitle
        buttonText
        buttonUrl
      }
      seo {
        title
        description
        canonicalUrl
        nextPage
        noindex
        nofollow
        image {
          url
          width
          height
          title
          description
        }
        ldType
        ldAuthor
        ldAuthorUrl
        ldPublished
        ldUpdated
      }
    }
  }
}
`;

const fetchHomePageIncyberData = async (locale = 'de') => {
  const variables = { locale };
  const res = await fetch(fetchConfig.endpoint as string, {
    method: 'POST',
    ...fetchConfig.params,
    body: JSON.stringify({ query: queryHomeIncyber, variables })
  });
  const json = await res.json();

  if (json.errors) {
    const { message } = json.errors[0];

    throw new Error(message);
  }

  return json.data;
};

const useHomeIncyberData = (locale: any) => {
  return useQuery({
    queryKey: ['homecyber', locale],
    refetchOnWindowFocus: false,
    queryFn: () => fetchHomePageIncyberData(locale)
  });
};

export { fetchHomePageIncyberData, useHomeIncyberData };
